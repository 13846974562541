import { React, useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react'
import { Button, ButtonGroup } from '@mui/material'

import MyList from '../MyList';

function AdminGuests(props) {
    const [fileInput, setFile] = useState(null)
    const { callbacks } = props
    const [data, setData] = useState(props.data)

    async function handleFileUpload(event) {
        event.preventDefault()

        const selectedFile = event?.target?.files?.[0]
        if (!selectedFile) return alert("No file selected")

        setFile(selectedFile)
    }

    useEffect(() => {
        if (!fileInput) return
        let formData = new FormData();
        formData.append('file', fileInput);

        callbacks.uploadGuestList(formData)
    }, [fileInput])

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    callbacks.discardNewGuest = function() {
        if (data && data?.[0]?.id && data[0].id != '__new__') return
        setData(data.slice(1))
    }

    return (<>
        <Button variant="contained" sx={{mr:1}} color="primary" onClick={() => {
            //insert at front of array
            if (data && data.length > 0 && !data?.[0].hasOwnProperty('id')) return
            setData([{}, ...data])
        }}>+</Button>

        <ButtonGroup orientation="horizontal">
            <Button variant="outlined" color="primary" onClick={() => {
                callbacks.downloadTemplate()
            }}>Sample</Button>
            <Button variant="contained" color="primary" component="label">
                Upload
                <input hidden accept=".csv" type="file" onChange={handleFileUpload} />
            </Button>
            <Button variant="contained" color="error" onClick={() => {
                callbacks.resetGuests()
            }}>Reset</Button>
        </ButtonGroup>
        <MyList editable={true} data={data} callbacks={callbacks}></MyList>
    </>)
}

export default AdminGuests
