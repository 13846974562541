
import { React, useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react'
import {  Drawer, List, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material'
import { SportsKabaddi, Person, EscalatorWarning, ChildFriendly, TableRestaurant, FormatListBulleted, TableChart, PinDrop, Settings } from '@mui/icons-material'

function Sidebar(props) {
    const { callbacks, stats } = props
    function closeDrawer() {
        callbacks.changeDrawerState(false)
    }

    function clickDrawer(view) {
        closeDrawer()
        callbacks.changeView(view)
    }

    return (<Drawer open={props.showDrawer} onClose={() => closeDrawer()}>
        <div
            tabIndex={0}
            role="button"
        >
            <List>
                <ListItem button onClick={() => clickDrawer('person')}>
                    <ListItemIcon> <FormatListBulleted /> </ListItemIcon>
                    <ListItemText primary={"Listă invitați"} />
                </ListItem>
                <ListItem button onClick={() => clickDrawer('table')}>
                    <ListItemIcon> <TableChart /> </ListItemIcon>
                    <ListItemText primary={"Listă mese"} />
                </ListItem>
                <ListItem button onClick={() => clickDrawer('map')}>
                    <ListItemIcon> <PinDrop /> </ListItemIcon>
                    <ListItemText primary={"Hartă sală"} />
                </ListItem>
                {props.loginDetails && props.loginDetails.role == 'admin' &&
                    <ListItem button onClick={() => clickDrawer('admin')}>
                        <ListItemIcon> <Settings /> </ListItemIcon>
                        <ListItemText primary={"Administrare"} />
                    </ListItem>
                }
            </List>

            <Divider />

            <List>
                <ListItem>
                    <ListItemIcon><SportsKabaddi /></ListItemIcon>
                    <ListItemText primary={"Perechi"} secondary={`${stats.checkedIn}/${stats.pairs}`} />
                </ListItem>
                <ListItem>
                    <ListItemIcon><Person /></ListItemIcon>
                    <ListItemText primary={"Persoane"} secondary={`${stats.checkedInTotal}/${stats.total}`} />
                </ListItem>
                <ListItem>
                    <ListItemIcon><EscalatorWarning /></ListItemIcon>
                    <ListItemText primary={"Copii"} secondary={`${stats.checkedInChildren}`} />
                </ListItem>
                <ListItem>
                    <ListItemIcon><ChildFriendly /></ListItemIcon>
                    <ListItemText primary={"Bebe"} secondary={`${stats.checkedInBaby}`} />
                </ListItem>
                <ListItem>
                    <ListItemIcon><TableRestaurant /></ListItemIcon>
                    <ListItemText primary={"Mese"} secondary={`${stats.fullTables}/${stats.numTables}`} />
                </ListItem>

            </List>
        </div>
    </Drawer>)
}

export default Sidebar
