import { React, useEffect, useState } from 'react'
import { TextField, Button, Grid, FormControl } from '@mui/material'
import "./NumericInput.css";

function parseAndSet(oldValue, increment) {
    let newValue = parseInt(oldValue) || 0
    newValue += increment
    if (newValue < 0) newValue = 0

    return newValue
}

function NumericInput(props) {
    let [value, setValue] = useState(props.value)

    useEffect(() => {
        props.onChange(value)
    }, [value])
    return (
        <>
            <Button variant="outlined" className="full-width-button" onClick={() => setValue(parseAndSet(value, +1))}>+</Button>
            <TextField
                className='inputText'
                variant='filled'
                label={props.label || "Button"}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={value}
            />
            <Button variant="outlined" className="full-width-button" onClick={() => setValue(parseAndSet(value, -1))}>-</Button>
        </>
    )
}

export default NumericInput