import { React, useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react'
import { Grid, Button, Box, ButtonGroup, Slider, Typography, Stack } from '@mui/material'

import InteractiveBallroom from '../InteractiveBallroom';

function AdminTables(props) {
    const [fileInput, setFile] = useState(null)
    const { callbacks } = props

    async function handleFileUpload(event) {
        event.preventDefault()

        const selectedFile = event?.target?.files?.[0]
        if (!selectedFile) return alert("No file selected")

        setFile(selectedFile)
    }

    useEffect(() => {
        if (!fileInput) return
        let formData = new FormData();
        formData.append('file', fileInput);

        callbacks.uploadBallroom(formData)
    }, [fileInput])

    const [radius, setRadius] = useState(30) // props?.tableLayout?.[0]?.radiusRatio || 30  
    const onRadiusChange = (event, newValue) => {
        setRadius(newValue)
    };

    return (<>
        <ButtonGroup sx={{mb:2}} orientation="horizontal">
            <Button variant="outlined" color="primary" component="label">
            Upload image
            <input hidden accept="image/*,img/*" type="file" onChange={handleFileUpload} />
            </Button>
            <Button variant="contained" color="primary" onClick={() => {
                callbacks.saveTableLayout()
            }}>Save</Button>
            <Button variant="contained" color="error" onClick={() => {
                callbacks.resetTableLayout()
            }}>Reset</Button>
        </ButtonGroup>
        {/* <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
            <Typography id="non-linear-slider" gutterBottom>
                Mărime mese
            </Typography>
            <Slider
                size="small"
                value={radius}
                onChange={onRadiusChange}
                aria-label="Small"
                valueLabelDisplay="auto"
            />
        </Stack> */}
      
        
        <InteractiveBallroom tableLayout={props.tableLayout} image={props.backgroundImage}/>
    </>)
}

export default AdminTables
