import { React, useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react'
import { Grid, Button, Box, ButtonGroup } from '@mui/material'

import { Stage, Layer, Text, Image } from 'react-konva';
import InteractiveTable from './InteractiveTable';

import "./InteractiveBallroom.css"

function InteractiveBallroom(props) {

    const divRef = useRef();
    const tablesRef = useRef();
    const {image} = props

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [imageSize, setImageSize] = useState({
        width: 0,
        height: 0
    });

    const [tables, setTables] = useState([])
    const [radius, setRadius] = useState(0)
    const [diameter, setDiameter] = useState(0)

    const isEditable = props.hasOwnProperty('editable') ? props.editable : true
    const selectedTable = props.hasOwnProperty('selectedTable') ? props.selectedTable : null

    useEffect(() => {
        const checkSize = () => {
            if (!divRef) return

            setWindowSize({
                width: divRef.current.clientWidth,
                height: divRef.current.clientHeight
            })
        };
        window.addEventListener("resize", checkSize);
        return () => window.removeEventListener("resize", checkSize);
    }, []);

    useEffect(() => {
        if (!divRef) return

        setWindowSize({
            width: divRef.current.clientWidth,
            height: divRef.current.clientHeight
        })
    }, [divRef]);

    useEffect(() => {
        if (!image) return

        const imageRatio = image.naturalWidth / image.naturalHeight
        
        setImageSize({
            width: windowSize.width,
            height: windowSize.width / imageRatio
        })
    }, [windowSize, image])

    useEffect(()=>{
        // const ratio = props.hasOwnProperty('radiusRatio') ? 100-props.radiusRatio : 30
        // console.log(ratio)
        const ratio = 20
        const newRadius = Math.floor(Math.min(imageSize.width, imageSize.height) / ratio)
        const newDiameter = newRadius * 2
        setRadius(newRadius)
        setDiameter(newDiameter)

        const tablesArr = props.tableLayout.map((table) => {
            return {
                ...table,
                x: newDiameter + table.x * imageSize.width,
                y: newDiameter + table.y * imageSize.height,
                selected: selectedTable == table.id
            }
        })

        setTables(tablesArr)
    }, [imageSize])

    function updatePositionCallback(id, position) {
        for (let i = 0; i < props.tableLayout.length; i++) {
            if (props.tableLayout[i].id != id) continue

            props.tableLayout[i].x = (position.x - diameter) / imageSize.width
            props.tableLayout[i].y = (position.y - diameter) / imageSize.height
        }
    }

    return (
        <div style={{width:'100%'}} ref={divRef}>
            <Stage width={windowSize.width} height={Math.max(windowSize.height, imageSize.height)} preventDefault={!!isEditable}>
                <Layer preventDefault={!!isEditable}>
                    <Image width={imageSize.width} height={imageSize.height} image={image} preventDefault={!!isEditable} />
                </Layer>
                <Layer ref={tablesRef} preventDefault={!!isEditable}>
                    { tables.map(table => {
                        return <InteractiveTable 
                                key={Math.random() + '_' + table.id} 
                                r={radius} 
                                editable={isEditable} 
                                updateTablePosition={updatePositionCallback} 
                                {...table} 
                                />
                    }) }
                </Layer>
            </Stage>
        </div>
    )
}

export default InteractiveBallroom
