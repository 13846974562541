import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material'
import "./Table.css";
import CheckIn from './CheckIn';
import {useState, useEffect} from 'react';

function MyTable(props) {
    const [showDropdown, setShowDropdown] = useState(null)

    const numPersons = props.values.reduce((prev, curr) => {
        return prev + curr.adults + curr.children
    }, 0)

    const checkedInPersons = props.values.reduce((prev, curr) => {
        return prev + curr.status.checkedInAdults + curr.status.checkedInChildren
    }, 0)

    function onClick(row) {
        if (showDropdown && showDropdown.id === row.id) {
            return setShowDropdown(null)
        }

        setShowDropdown(row)
    }

    const rows = props.values.map((row) => {
        const retVal =  [
            <TableRow
                key={'row_' + row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => onClick(row)}
            >
                <TableCell component="th" scope="row">
                    {row.names.join(" & ")}
                </TableCell>
                <TableCell align="right">{row.status.checkedIn ? (row.status.checkedInAdults + row.status.checkedInChildren) + '/' : ''}
                    {row.adults}
                    {row.children > 0 && `+${row.children}`}
                </TableCell>
                <TableCell align="right" className={row.status.checkedIn ? 'checkedIn' : ''}>{row.status.checkedIn ? 'Da' : 'Nu'}</TableCell>
            </TableRow>
        ]

        if (showDropdown && showDropdown.id === row.id) {
            retVal.push(
                <TableRow key={'checkin_row_' + row.id} sx={{ border: 0, padding: 0, margin: 0 }} >
                    <TableCell colSpan="3" scope="row">
                        <CheckIn {...props} {...row} borderless />
                    </TableCell>
                </TableRow>
            )
        }
        return retVal
    }).reduce((prev, curr) => { return prev.concat(curr) }, [])

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead sx={{ backgroundColor: '#e8e8e8' }} >
                    <TableRow>
                        <TableCell>
                            <Typography component="div">
                                <Box sx={{ fontWeight: 'bold' }} display="inline">
                                    Masa {props.num}
                                </Box>
                                &nbsp;- {checkedInPersons}/{numPersons} pers
                            </Typography>
                        </TableCell>
                        <TableCell sx={{fontWeight: 'bold'}} align="right">Persoane</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}} align="right">Venit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (row))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MyTable
