import { React, useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react'
import { Grid, Button, Box, ButtonGroup, RadioGroup, Radio, FormControl, FormControlLabel, } from '@mui/material'

import AdminGuests from './AdminGuests'
import AdminTables from './AdminTables'


function Admin(props) {
    const [view, setView] = useState('guests')

    const handleChange = (event) => {
        setView(event.target.value);
    };
    return (<>
        <FormControl>
            <RadioGroup
                row
                value={view}
                onChange={handleChange}
            >
                <FormControlLabel value="guests" control={<Radio />} label="Invitați" />
                <FormControlLabel value="tables" control={<Radio />} label="Hartă" />
            </RadioGroup>
        </FormControl>
        <hr/>
        <div>
        {view == 'guests' && <AdminGuests {...props} />}
        {view == 'tables' && <AdminTables {...props} />}
        </div>
    </>)
}

export default Admin
