import { React, useState } from 'react'
import CheckIn from './CheckIn'
import { ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { Check, Edit } from '@mui/icons-material'

import EditGuest from './Admin/EditGuest'

function MyListItem(props) {
    return (
            <>
            <ListItem onClick={() => props.onClick(props.idx)}>
                {!props.editable && 
                <ListItemIcon>
                    {props.status.checkedIn && <Check /> || ''}
                </ListItemIcon>
                }
                { props?.names ?
                <ListItemText primary={props?.names?.join(' & ')} secondary={`Masa ${props.table}`}/> :
                <ListItemText primary='New'/>
                }
            </ListItem>
            {!props.editable && props.showDropdown && (<CheckIn {...props} />)}
            {props.editable && props.showDropdown && (<EditGuest {...props} />)}
        </>
    )
}

export default MyListItem