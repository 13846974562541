import { React, useEffect, useState } from 'react'
import { AppBar, Toolbar, Typography, IconButton, MenuItem, Menu } from '@mui/material'
import { Menu as MenuIcon, AccountCircle } from '@mui/icons-material'

import './Toolbar.css'

function Stats(props) {
    const { callbacks, view } = props

    const [title, setTitle] = useState(view)
    
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    useEffect(() => {
        switch(view) {
            case 'person':
                return setTitle('Listă persoane')
            case 'table':
                return setTitle('Listă mese')
            case 'map':
                return setTitle('Harta sălii')
            default:
                return setTitle('Administrare')
        }
    }, [view])

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleLogout() {
        handleClose()
        callbacks.logout()
    }

    return (
        <AppBar position="static">
            <Toolbar>
        
                <IconButton className='menuButton' color="inherit" aria-label="Menu" onClick={() => callbacks.changeDrawerState(true)}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className='grow'>
                    {title}
                </Typography>

                <IconButton
                    aria-owns={open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );

}

export default Stats
