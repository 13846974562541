import { React, useState, useEffect } from 'react'

import Grid from '@mui/material/Grid';
import MyTable from "./Table"

function TableList(props) {
    const [tablesView, setTablesView] = useState(props.tables)
    
    useEffect(() => {
        if (!props.filter || !Array.isArray(props.filter)) return

        const filteredIds = new Set(props.filter)

        let newTableView = {}
        for (let t in props.tables) {
            let table = props.tables[t]
            for (let item of table) {
                if (filteredIds.has(item.id)) {
                    newTableView[t] = table
                    break;
                }
            }
        }

        setTablesView(newTableView)
    }, [props.filter, props.tables])

    return (
        <Grid container spacing={2} p={2}>
            {
                Object.keys(tablesView).map(t => (
                    <Grid item key={"my_table_" + t} xs={12} md={6}>
                    <MyTable 
                        num={t} 
                        values={tablesView[t]}
                        checkIn={props.checkIn}
                        openModal={props.openModal}
                    />
                </Grid>
                ))
            }
        </Grid>
    )
}
export default TableList
