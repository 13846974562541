import { React, useEffect, useState } from 'react';
import { Circle, Text } from 'react-konva';

function InteractiveTable(props) {
    const [state, setState] = useState ({
        isDragging: false,
        x: props.x,
        y: props.y,
        r: props.r,
        editable: props.editable,
        selected: props.selected,
        highIntensity: true,
        id: props.id
    })
    const [circle, setCircleRef] = useState(null)

    useEffect(() => {
        if (!circle) return;
        if (state.selected) {
            const duration = 0.5
            const newColor = state.highIntensity ? 'rgba(255, 0, 0, 0.25)' : 'rgba(255, 0, 0, 0.5)'

            circle.to({
                fill: newColor,
                duration,
            })

            const timeout = setTimeout(() => {
                setState(oldState => {
                    return {
                        ...oldState,
                        highIntensity: !oldState.highIntensity,
                    }
                })
            }, duration * 1000)

            return () => {
                clearTimeout(timeout)
            }
        } else {
            circle.to({
                fill: 'rgba(0, 0, 0, 0.1)',
                duration: 0,
            })
        }
    }, [circle, state])

    return (
    <>
        <Text
            text={state.id}
            x={state.x - state.r/2}
            y={state.y - 5}
            fill={state.isDragging ? 'green' : 'black'}
            preventDefault={!!state.editable}
        />
        <Circle
            x={state.x}
            y={state.y}
            radius={state.r}
            // fill={state.selected ? "rgba(0, 255, 0, 0.2)" : 'rgba(0, 0, 0, 0.1)'}
            draggable={!!state.editable}
            ref={(node) => setCircleRef(node)}
            // stroke={state.isDragging ? 'green' : 'black'}
            preventDefault={!!state.editable}

            onDragStart={() => {
                setState(oldState => { 
                    return {
                        ...oldState,
                        isDragging: true,
                    }
                });
            }}
            onDragMove={(e) => {
                setState(oldState => {
                    return {
                        ...oldState,
                        x: e.target.x(),
                        y: e.target.y(),
                    }
                });
            }}
            
            onDragEnd={(e) => {
                setState(oldState => {
                    return {
                        ... oldState,
                        isDragging: false,
                        x: e.target.x(),
                        y: e.target.y(),
                    }
                });

                props.updateTablePosition(state.id, {x: e.target.x(), y: e.target.y()})
            }}
        />        
    </>)
}

export default InteractiveTable
