import * as React from 'react';

import { 
    Dialog, AppBar, Toolbar, IconButton, Typography, Slide,
    FormControl, InputLabel, OutlinedInput, InputAdornment,
    List, ListItem, Button
} from '@mui/material'

import {
    Visibility, VisibilityOff
} from '@mui/icons-material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginModal(props) {
    const [values, setValues] = React.useState({
        event: '',
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleKeyUp = function(e) {
        if (e.keyCode == 13) {
            props.login(values.event, values.password)
        }
    }

    return (
        <div>
            <Dialog
                fullScreen
                open={props.open}
                onClose={props.onModalClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Login
                        </Typography>
                    </Toolbar>
                </AppBar>

                <List>
                    <ListItem>
                        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-event">Event</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-event"
                                type='text'
                                value={values.event}
                                onChange={handleChange('event')}
                                onKeyUp={handleKeyUp}
                                label="Event"
                            />
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                onKeyUp={handleKeyUp}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </ListItem>
                    <ListItem>
                        <FormControl fullWidth>
                            <Button variant="contained" onClick={() => props.login(values.event, values.password)} >Login</Button>
                        </FormControl>
                    </ListItem>
                </List>
            </Dialog>
        </div>
    );
}
