import { React, useState } from 'react'
import { Button, Box, ButtonGroup, TextField, FormControl, Stack } from '@mui/material'


function EditGuest(props) {
    let [state, setState] = useState({
        id: props.hasOwnProperty('id') ? props.id : '__new__',
        name_1: props?.names?.[0] || '',
        name_2: props?.names?.[1] || '',
        pseudonims: props?.pseudonims || [],
        adults: props?.adults || 0,
        children: props?.children || 0,
        baby: props?.baby || 0,
        table: props?.table || '',
    })

    const {callbacks} = props

    const handleChange = (key, event) => {
        const value = event.target.value;

        switch (key) {
            case 'name_1':
            case 'name_2':
                const otherKey = key == 'name_1' ? 'name_2' : 'name_1'

                const adults = (value?.length ? 1 : 0) + (state[otherKey]?.length ? 1 : 0) 
                return setState({ ...state, [key]: value, adults })
            case 'table':
                return setState({ ...state, [key]: value })
            case 'pseudonims':
                return setState({ ...state, [key]: value.split('\n') })
            case 'adults':
            case 'children':
            case 'baby':
                const v = parseInt(value) || 0
                if (v < 0) return
                return setState({ ...state, [key]: v})
            default:
                return
        }
    };

    return (
        <Box component="form" sx={{ p: 1, border: '1px dashed grey' }}>
            <FormControl autoComplete="off">
            <TextField
                required
                error={!state.name_1 || state.name_1.length == 0}
                id="outlined-required"
                label="Nume 1"
                sx={{ mb: 2 }}
                value={state.name_1}
                onChange={event => handleChange('name_1', event)}
            />
            <TextField
                label="Nume 2"
                sx={{ mb: 2 }}
                value={state.name_2}
                onChange={event => handleChange('name_2', event)}
            />
            <TextField
                id="outlined-multiline-flexible"
                label="Porecle"
                multiline
                maxRows={4}
                sx={{ mb: 2 }}
                value={state.pseudonims.join('\n')}
                onChange={event => handleChange('pseudonims', event)}
            />
            <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
                <TextField
                    id="outlined-number"
                    label="Adulți"
                    type="number"
                    value={state.adults}
                    onChange={event => handleChange('adults', event)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    id="outlined-number"
                    label="Copii"
                    type="number"
                    value={state.children}
                    onChange={event => handleChange('children', event)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    id="outlined-number"
                    label="Bebe"
                    type="number"
                    value={state.baby}
                    onChange={event => handleChange('baby', event)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Stack>
            <TextField
                required
                error={!state.table || state.table.length == 0}
                id="outlined-required"
                label="Masa"
                sx={{ mb: 2 }}
                value={state.table}
                onChange={event => handleChange('table', event)}
            />

            <ButtonGroup
                orientation="horizontal"
            >
                    <Button
                    disabled={!state.name_1 || state.name_1.length == 0 || !state.table || state.table.length == 0}
                    variant="contained" onClick={() => callbacks.saveGuest(state)}>Save</Button>
                { state.id == '__new__' &&
                    <Button variant="contained" color='error' onClick={() => callbacks.discardNewGuest(state)}>Discard</Button> ||
                    <Button variant="contained" color='error' onClick={() => callbacks.deleteGuest(state)}>Delete</Button>
                }
            </ButtonGroup>
            </FormControl>
        </Box>
    )
}

export default EditGuest