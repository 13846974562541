import { React, useState, useEffect } from "react";
import axios from "axios";

import { Container, TextField, IconButton, InputAdornment, Alert, Snackbar } from '@mui/material'
import { Clear } from '@mui/icons-material'

import MyList from "./Components/MyList"
import TableList from "./Components/TableList"
import FullScreenModal from './Components/FullScreenModal';
import LoginModal from './Components/LoginModal';
import Admin from './Components/Admin/Admin';
import Toolbar from './Components/Toolbar';
import Sidebar from "./Components/Sidebar";

import io from 'socket.io-client';

import "./App.css";
import InteractiveBallroom from "./Components/InteractiveBallroom";
import C from './constants';

const socket = io(C.apiURL);

function emptyStats() {
	return { pairs: 0, persons: 0, checkedIn: 0, adults: 0, children: 0, baby: 0, checkedInAdults: 0, checkedInChildren: 0, checkedInBaby: 0, checkedInTotal: 0, fullTables: 0, numTables: 0 }
}

function App() {
	const [inputText, setInputText] = useState("");
	const [view, setView] = useState('person')
	const [data, setData] = useState([])
	const [filteredIds, setFilteredIds] = useState([])
	const [api, setApi] = useState(null)
	const [tables, setTables] = useState({})
	const [tableLayout, setTableLayout] = useState({})
	const [dirty, setDirty] = useState(false)

	const [stats, setStats] = useState(emptyStats())
	const [modalOpen, setModalOpen] = useState(false)
	const [modalTable, setModalTable] = useState(0)
	const [isConnected, setIsConnected] = useState(socket.connected)
	const [loggedIn, setLoggedIn] = useState(null)
	const [authorization, setAuthorization] = useState(null)
	const [showDrawer, setShowDrawer] = useState(false)

	const [error, setError] = useState(null)

	const [backgroundImage, setBackgroundImage] = useState(null)

	useEffect(() => {
		const auth = localStorage.getItem('authorization')
		setAuthorization(auth)

		const loginDetails = localStorage.getItem('loginDetails')
		setLoggedIn(JSON.parse(loginDetails))
	}, [])

	useEffect(() => {
		if (!authorization) return
		const options = {
			baseURL: C.apiURL,
			timeout: 20000,
			headers: {
				Authorization: 'Basic ' + authorization
			}
		}

		let instance = axios.create(options);
		setApi({ instance })
	}, [authorization])

	useEffect(() => {
		socket.on('connect', () => {
			setIsConnected(true);
		});

		socket.on('disconnect', () => {
			setIsConnected(false);
		});

		socket.on('data', (incoming) => {
			setData(oldData => {
				let newData = [...oldData]
				for (let i = 0; i < newData.length; i++) {
					if (newData[i].id == incoming.id) {
						const augmentations = {
							tokens: [...incoming.names, incoming.pseudonims, incoming.table].join('/')
								.toLowerCase()
								.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
								.split(/[^\w]/mg)
								.filter(word => word)
						}

						augmentations.words = augmentations.tokens.join(' ')

						newData[i] = {...incoming, ...augmentations};
						break;
					}
				}
				return newData
			})
		});

		socket.on('dirty', (incoming) => {
			setDirty(prevValue => !prevValue)
		})

		return () => {
			socket.off('connect');
			socket.off('disconnect');
			socket.off('data');
		};
	}, []);

	useEffect(() => {
		if (!api || !authorization) return;
		api.instance.get('/list').then(response => {
			let data = response.data
				.sort((a, b) => a.names[0].localeCompare(b.names[0]))
				.map(item => {
					const augmentations = {
						tokens: [...item.names, item.pseudonims, item.table].join('/')
							.toLowerCase()
							.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
							.split(/[^\w]/mg)
							.filter(word => word)
					}

					augmentations.words = augmentations.tokens.join(' ')

					return {
						...item,
						...augmentations
					}
				})

			localStorage.setItem('data', JSON.stringify(data))

			setData(data)
			setFilteredIds(data.map(item => item.id))

		}).catch(e => {
			if (e?.response?.status == 401) {
				return callbacks.logout()
			}

			const saved = localStorage.getItem('data')
			if (saved) {
				const localData = JSON.parse(saved)
				setData(localData)
				setFilteredIds(localData.map(item => item.id))
			}
		})

		api.instance.get('/table-layout').then(response => {
			localStorage.setItem('tableLayout', JSON.stringify(response.data))
			setTableLayout(response.data)
		}).catch(e => {
			if (e?.response?.status == 401) {
				return callbacks.logout()
			}

			const saved = localStorage.getItem('tableLayout')
			if (saved) {
				const localData = JSON.parse(saved)
				setTableLayout(localData)
			}
		})

		const image = new window.Image();

		api.instance.get('/ballroom', { responseType: 'blob' }).then(response => {
			// response.data is a buffer, so we need to convert it to base64
			let imgUrl = URL.createObjectURL(response.data)
			image.src = imgUrl;
		}).catch(e => {
			if (e?.response?.status == 401) {
				return callbacks.logout()
			}

			image.src = 'default_ballroom.png'
		}).finally(() => {
			image.onload = () => {
				setBackgroundImage(image)
			}
		})
	}, [api, dirty])

	function checkIn(guest) {
		if (!api || !authorization) return;

		api.instance.post('/checkin', guest).then((resp) => {
			console.log('Checkin', resp.data)
		}).catch(e => {
			setError(formatAxiosError(e))
		})
	}

	function login(event, secret) {
		axios.post(C.apiURL + '/login', { event, secret }).then((resp) => {
			const hash = window.btoa(event + ':' + secret)
			localStorage.setItem('authorization', hash)
			localStorage.setItem('loginDetails', JSON.stringify(resp.data))

			setAuthorization(hash)
			setLoggedIn(resp.data)
		}).catch(e => {
			setError(formatAxiosError(e))
		})
	}

	useEffect(() => {
		const newStats = emptyStats()
		const t = {}
		data.forEach((item, idx, arr) => {
			newStats.checkedIn += item.status.checkedIn ? 1 : 0
			newStats.adults += item.adults || 0
			newStats.children += item.children || 0
			newStats.baby += item.baby || 0
			newStats.checkedInAdults += item.status.checkedInAdults || 0
			newStats.checkedInChildren += item.status.checkedInChildren || 0
			newStats.checkedInBaby += item.status.checkedInBaby || 0
			newStats.pairs++

			t[item.table] = t.hasOwnProperty(item.table) ? t[item.table] : true
			if (!item.status.checkedIn) t[item.table] = false
		})

		newStats.total = newStats.adults + newStats.children
		newStats.checkedInTotal = newStats.checkedInAdults + newStats.checkedInChildren
		newStats.numTables = Object.keys(t).length
		newStats.fullTables = Object.values(t).filter(value => value == true).length

		setStats(newStats)

		let tables = data.reduce((acc, item) => {
			acc[item.table] = acc[item.table] || []
			acc[item.table].push(item)

			return acc
		}, {})

		setTables(tables)
	}, [data])

	useEffect(() => {
		if (inputText.length === 0) {
			return setFilteredIds(data.map(item => item.id))
		}

		const query = inputText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')

		let result = querySearch(query, data)
		setFilteredIds(result.map(item => item.id))
	}, [inputText, data])

	function querySearch(needle, haystack) {
		let words = needle.split(/[^\w]/mg).filter(q => q)
		return haystack.filter(element =>
			words.every(word => {
				if (parseInt(word)) {
					return element.tokens.some(token => token == word)
				}
				return element.tokens.some(token => token.startsWith(word))
			})
		)
	}

	let inputHandler = (e) => {
		setInputText(e.target.value)
	};

	function clearInput() {
		setInputText('')
	}

	function handleModalClose() {
		setModalOpen(false);
	}

	function openModal(table) {
		setModalOpen(true)
		setModalTable(table)
	}

	function handleErrorClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}

		setError(null);
	}

	function formatAxiosError(error) {
		if (error.response) {
			if(error.response.data) {
				return error.response.data 
			}
			if(error.response.status) {
				return error.response.status 
			}
			if(error.response.headers) {
				return error.response.headers 
			}
		} 
		return error.message
	}

	const adminCallbacks = {
		saveTableLayout: () => {
			if (!api || !authorization) return;

			api.instance.post('/save/table-layout', [...tableLayout]).then((resp) => {
				console.log('Saved', resp.data)
				setDirty(prevValue => !prevValue)
			}).catch(e => {
				setError(formatAxiosError(e))
			})
		},
		resetTableLayout: () => {
			if (!api || !authorization) return;

			api.instance.get('/reset/table-layout').then((resp) => {
				console.log('Reset', resp.data)
				setDirty(prevValue => !prevValue)
			}).catch(e => {
				setError(formatAxiosError(e))
			})
		},
		uploadGuestList : (data) => {
			if (!api || !authorization) return;

			// post file to server
			api.instance.post('/upload/guests', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((resp) => {
				console.log('Uploaded', resp.data)
				setDirty(prevValue => !prevValue)
			}).catch(e => {
				setError(formatAxiosError(e))
			})
		},
		uploadBallroom: (data) => {
			if (!api || !authorization) return;

			// post file to server
			api.instance.post('/upload/ballroom', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((resp) => {
				console.log('Uploaded', resp.data)
				setDirty(prevValue => !prevValue)
			}).catch(e => {
				setError(formatAxiosError(e))
			})
		},
		downloadTemplate: () => {
			if (!api || !authorization) return;

			api.instance.get('/guests-template', {
				responseType: 'blob'
			}).then((response) => {
				// create file link in browser's memory
				const href = URL.createObjectURL(response.data);

				// create "a" HTML element with href to file & click
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', 'template.csv'); //or any other extension
				document.body.appendChild(link);
				link.click();

				// clean up "a" element & remove ObjectURL
				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			}).catch(e => {
				setError(formatAxiosError(e))
			})
		},
		resetGuests: () => {
			if (!api || !authorization) return;

			api.instance.get('/reset').then((resp) => {
				console.log('Reset', resp.data)
				setDirty(prevValue => !prevValue)
			}).catch(e => {
				setError(formatAxiosError(e))
			})
		},
		saveGuest: (guest) => {
			if (!api || !authorization) return;

			guest.pseudonims = guest.pseudonims.filter(p => p)

			api.instance.post('/save/guest', guest).then((resp) => {
				console.log('Saved', resp.data)
				setDirty(prevValue => !prevValue)
			}).catch(e => {
				setError(formatAxiosError(e))
			})
		},
		deleteGuest: (guest) => {
			if (!api || !authorization) return;

			api.instance.post('/delete/guest', guest).then((resp) => {
				console.log('Saved', resp.data)
				setDirty(prevValue => !prevValue)
			}).catch(e => {
				setError(formatAxiosError(e))
			})
		},
	}

	const callbacks = {
		changeView: (view) => {
			setView(view)
		},
		changeDrawerState: (newState) => {
			setShowDrawer(newState)
		},
		logout: () => {
			//clear local storage
			localStorage.clear()
			setLoggedIn(null)
		}
	}

	return (
		<>
			<Snackbar open={error != null} autoHideDuration={30000} onClose={handleErrorClose}>
				<Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
					{error}
				</Alert>
			</Snackbar>

		{!loggedIn ?
			<LoginModal open={true} login={login}></LoginModal>
			:
			modalOpen ?
				<FullScreenModal 
					open={modalOpen} 
					onModalClose={handleModalClose} 
					tableLayout={tableLayout} 
					table={modalTable}
					tables={tables}
					backgroundImage={backgroundImage}
					checkIn={checkIn}
					values={data.filter(row => row.table == modalTable)}
				>
				</FullScreenModal>
				:
				<div className="main">
					<Toolbar callbacks={callbacks} view={view}></Toolbar>
					

					<Sidebar showDrawer={showDrawer} stats={stats} callbacks={callbacks} view={view} loginDetails={loggedIn}></Sidebar>
					<Container>
						<div className="search">

							{['person', 'table'].indexOf(view) != -1 &&
								<TextField
									id="outlined-basic"
									value={inputText}
									onChange={inputHandler}
									variant="outlined"
									fullWidth
									label="Search"
									autoComplete="off"
									InputProps={{
										endAdornment:
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={clearInput}
													onMouseDown={clearInput}
													edge="end"
												>
													{inputText.length > 0 && <Clear />}
												</IconButton>
											</InputAdornment>
									}}
								/>}
						</div>
						{
							(() => {
								switch (view) {
									case 'person':
										return <MyList filter={filteredIds} data={data} checkIn={checkIn} openModal={openModal} />
									case 'table':
										return <TableList filter={filteredIds} tables={tables} checkIn={checkIn} openModal={openModal} />
									case 'map':
										return <InteractiveBallroom editable={false} tableLayout={tableLayout} image={backgroundImage}/>
									case 'admin':
										return loggedIn && loggedIn.role=='admin' && <Admin callbacks={adminCallbacks} data={data} tableLayout={tableLayout} backgroundImage={backgroundImage}></Admin>
								}
							})()
						}
					</Container>
				</div>
	}
	</>);
}

export default App;