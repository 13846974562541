import { React, useState } from 'react'
import { Grid, Button, Box, ButtonGroup } from '@mui/material'

import NumericInput from './NumericInput';

function CheckIn(props) {
    let [state, setState] = useState({
        id: props.id,
        adults: props.status.checkedIn ? props.status.checkedInAdults : props.adults,
        children: props.status.checkedIn ? props.status.checkedInChildren : props.children,
        baby: props.status.checkedIn ? props.status.checkedInBaby : props.baby
    })

    const buttons = [
        <Button key={0} variant="contained" onClick={() => props.checkIn({ ...state, table: props.table })}>{props.status.checkedIn ? 'Edit' : 'Sosit'}</Button>,
        <Button key={1} variant="outlined" onClick={ () => props.openModal(props.table) }>Masa</Button>
    ] 

    if (props.status.checkedIn) {
        buttons.splice(1, 0, <Button key={2} variant="outlined" color="error" onClick={() => props.checkIn({ ...state, table: props.table, remove: true })}>Șterge</Button>)
    }

    const boxStyle = {
        p: 1
    }
    if (!props.hasOwnProperty('borderless')) {
        boxStyle['borderTop'] = '1px dashed grey'
        boxStyle['borderBottom'] = '1px dashed grey'
    }
    return (
        <Box sx={boxStyle}>
            <Grid container spacing={1} p={1}>
                <Grid item xs={3} md={3}>
                    <NumericInput
                        label="Adulți"
                        value={state.adults}
                        onChange={adults => setState({ ...state, adults })}
                    />
                </Grid>
                <Grid item xs={3} md={3}>

                    <NumericInput
                        label="Copii"
                        value={state.children}
                        onChange={children => setState({ ...state, children })}
                    />
                </Grid>
                <Grid item xs={3} md={3}>

                    <NumericInput
                        label="Bebe"
                        value={state.baby}
                        onChange={baby => setState({ ...state, baby })}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    <ButtonGroup
                        orientation="vertical"
                    >
                        {buttons}
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CheckIn