import { React, useEffect, useState } from 'react'
import MyListItem from './MyListItem'
import {List} from '@mui/material'

function MyList(props) {
    let [showDropdown, setShowDropdown] = useState({ })
    
    function handleClick(idx) {
        let newValue = { ...new Array(props.data.length).fill(false) }
        let oldValue = showDropdown[idx]

        newValue[idx] = !oldValue
        setShowDropdown(newValue)
    }

    useEffect(() => {
        let v = {...new Array(props.data.length).fill(false)}
        for (let i in props.data) {
            let guest = props.data[i]
            if (!guest.hasOwnProperty('id')) v[i] = true
        }
        setShowDropdown(v)
    }, [props.data])

    const isEditable = props.hasOwnProperty('editable') ? props.editable : false

    return (
        <List>
            {props.data.filter(item => props.filter ? props.filter.indexOf(item.id) != -1 : true).map((item, idx) => (
                <MyListItem 
                            key={idx} 
                            idx={idx} 
                            checkIn={props.checkIn} 
                            openModal={props.openModal} 
                            showDropdown={showDropdown[idx]} 
                            onClick={handleClick}
                            editable={isEditable}
                            callbacks={props.callbacks}
                            {...item}
                />
            ))}
        </List>
    )
}

export default MyList