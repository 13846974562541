
import { React, useEffect, useLayoutEffect, useRef, useState } from 'react'
import "./Ballroom.css";

const tables = [
    [0, 0],
    [705, 410],
    [775, 340],
    [692, 315],
    [738, 238],
    [642, 237],
    [665, 145],
    [567, 175],
    [562, 80],
    [360, 672],
    [280, 710],
    [315, 790],
    [267,628],
    [178, 648],
    [205, 730],
    [202, 558],
    [117, 522],
    [103, 610],
    [173, 466],
    [114, 403],
    [42, 464],
    [176, 338],
    [57, 334],
]
tables.prezidiu = [166, 192]

function Ballroom(props) {
    const tablePos = tables[props.table]
    const ballroomImg = useRef(null)
    const baseOverlayWidth = 60
    const [overlayWidth, setOverlayWidth] = useState(baseOverlayWidth)
    const [top, setTop] = useState(tablePos[1])
    const [left, setLeft] = useState(tablePos[0])
    const [multiplier, setMultiplier] = useState(1)
    const [display, setDisplay] = useState('none')
    
    function updateOverlayWidth() {
        if (!ballroomImg || !ballroomImg.current) return setTimeout(updateOverlayWidth, 10)

        const naturalWidth = ballroomImg.current.naturalWidth
        const width = ballroomImg.current.width
        const m = width / naturalWidth

        if (!m) return setTimeout(updateOverlayWidth, 10)
        
        setMultiplier(width / naturalWidth)
    }

    function rescaleOverlay() {
        const newOverlayWidth = Math.round(baseOverlayWidth * multiplier)
        const newTop = Math.round(tablePos[1] * multiplier)
        const newLeft = Math.round(tablePos[0] * multiplier)

        setOverlayWidth(newOverlayWidth)
        setTop(newTop)
        setLeft(newLeft)
        setDisplay('block')
    }

    useEffect(() => {
        rescaleOverlay()
    }, [])

    useEffect(() => {
        rescaleOverlay()    
    }, [multiplier, props.table])

    useLayoutEffect(() => {
        function updateSize() {
            updateOverlayWidth()
        }

        window.addEventListener('resize', updateSize);
        updateOverlayWidth()

        return () => window.removeEventListener('resize', updateSize);
    }, [])

    return (
    <div className='image-container'>
        <img 
            ref={ballroomImg}
            className='image'
            src={'oglinda.png'}
            alt={'Oglinda sala'}
            loading="lazy"
        />
        
        <div className='overlay' style={{ width: overlayWidth, height: overlayWidth, top, left, display }}></div>
    </div>
    )
}

export default Ballroom
