import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import MyTable from "./Table"
import Ballroom from './Ballroom';
import InteractiveBallroom from './InteractiveBallroom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenModal(props) {
    return (
        <div>
            <Dialog
                fullScreen
                open={props.open}
                onClose={props.onModalClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.onModalClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Masa {props.table}
                        </Typography>
                        
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <MyTable 
                            num={props.table}
                            values={props.values}
                            checkIn={props.checkIn}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <InteractiveBallroom
                            tableLayout={props.tableLayout}     
                            editable={false} 
                            selectedTable={props.table} 
                            image={props.backgroundImage}
                        ></InteractiveBallroom>
                    </ListItem>
                </List>
            </Dialog>
        </div>
    );
}
